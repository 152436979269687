import { useStaticQuery, graphql } from "gatsby";

export const usePostsByCatagories = () => {
  const { allArticle } = useStaticQuery(categoriesQuery);
  return allArticle;
};

const categoriesQuery = graphql`
  query allPostsByCategory {
    allArticle(sort: { fields: [date], order: DESC }, limit: 1000, filter: {}) {
      group(field: category___name, limit: 50) {
        categoryName: fieldValue
        nodes {
          title
          author {
            name
            title
          }
          date
          timeToRead
          category {
            color
            slug
          }
          tags {
            name
          }
          featured
          premium
          class
          slug
          private
        }
        totalCount
      }
      totalCount
    }
  }
`;
